import React from 'react'
import { navigate } from 'gatsby'
import {useDispatch} from 'react-redux'
import {SEO, Input, Button} from 'components'
import 'scss/screens/_login.scss'
import { quitLoading, setLoading, showError, showSuccess } from 'utils'
import { AuthService } from 'services'
import {AdminLayout} from 'widgets'

type Form = {
    email: string,
    password: string
}
const Login = () => {
    const IFormState = {
        email: '',
        password: ''
    }
    const dispatch = useDispatch()
    const [form, setForm] = React.useState<Form>(IFormState)
    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setLoading()
            const res = await AuthService.login(form)
            dispatch({
                type: 'SET_USER',
                payload: res
            })
            showSuccess('Ha iniciado sesión correctamente')
            navigate('/')
        } catch (error) {
            showError(error?.response?.data?.message)
        }finally{
            quitLoading()
        }
    }
    const change = (key: keyof Form, value: string) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    return(
        <React.Fragment>
            <SEO
                title="Login"
            />
            <div className="login">
                <div className="w-100 container">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={ submit }>
                                <div className="w-100 text-center">
                                    <div className="container-icon">
                                        <i className="fa fa-user"></i>
                                    </div> 
                                </div>
                                <div className="form-group">
                                    <Input 
                                        name="email" 
                                        placeholder="Correo Electrónico"
                                        value={form.email}
                                        onChange={(value: string) => change('email', value) }
                                    />
                                </div>
                                <div className="form-group">
                                    <Input 
                                        type="password" 
                                        name="password"
                                        placeholder="Contraseña"
                                        value={form.password}
                                        onChange={(value: string) => change('password', value) } />
                                </div>
                                <div className="text-right">
                                    <Button
                                        // className="success "
                                        label="Iniciar Sesion"
                                        type="submit"
                                        disabled={ !form.email || !form.password }
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login